.container {
    max-width: 550px;
    background: $color-4;
    border-radius: 15px;
    margin: auto;
    margin-top: 80px;
    padding: 30px;
    color: $color-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    // ----  profile card only  ----
    .bt-close {
        position: absolute;
        right: 20px;
        top: 20px;
        @include bt-icon($color-1, 1.2em);

        padding: 5px 10px;

        &:hover {
            @include bt-icon-hover;
        }
    }
}



.avatar-profile {
    width: 100px;
    height: 100px;
    margin: 30px;
    @include round-avatar ($color-1);
}

.username {
    font-weight: 600;
    // max-width: 85%;
    margin: 10px 0;
    font-size: 1.7em;
    position: relative;
    text-align: center;

    @include mobile-only {
        font-size: 1.5em;
    }
}

.edit-field {
    color: lighten($color-1, 20%);
    font-family: $font-1;
    width: 105%;
    height: 100%;
    border-radius: 6px;
    border: 1px solid $color-3;
    font-weight: 600;
    font-size: 2em;
    text-align: center;
    margin: 10px 0;
}


.picto {
    @include picto(22px, $color-1, 0.2);
    margin: 15px;
    position: absolute;
    left: 100%;

    &:hover {
        @include picto-hover(0.7);
    }

    &-surrounded {
        @include pictoSurrounded(30px);
        margin-top: -6px;
    }

    &-surrounded:hover {
        @include picto-hoverSurrounded;
    }
}


.separatorH {
    height: 1px;
    width: 90%;
    margin: 30px 0;
    background-color: $color-3;
}

.signout-delete-account {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.bt-signout-account,
.bt-delete-account {

    &:hover {
        @include bt-icon-hover;
    }
}

.bt-signout-account {
    @include bt-icon($color-1, 1.2em);
}

.bt-delete-account {
    @include bt-icon($color-2, 1.2em);
}

// ----  delete profile only  ----

.sad-pic,
.cry-pic {
    width: 126px;
    height: 126px;
    margin-top: -60px;
}

.sad-pic {
    background: url(../../assets/images/emoji-sad.png) no-repeat;

}

.cry-pic {
    background: url(../../assets/images/emoji-cry.png) no-repeat;

}

.msg-quit {
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.picto-warning {
    width: 36px;
    height: 37px;
    background: url(../../assets/images/picto-warning.svg) no-repeat;
    margin: 15px;
}

.text {
    line-height: 1.5em;
    text-align: center;

    & span strong {
        font-weight: 600;
        display: block;
        margin-bottom: -9px;
    }

}

.choices {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;

    .bt {
        width: 166px;
        @include button($color-2);
        padding: 10px;
        font-family: inherit;
        transition: background 200ms;


        &-stay {
            background: $color-5;
        }

        &-quit {
            background: $color-2;
        }
    }
}



.divRow {
    display: flex;
    flex-direction: row;
    position: relative;
}