.post-container {
    margin: 20px;
    background: $color-4;
    border-radius: 15px;
    margin-bottom: 30px;
    color: $color-1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.post-container,
.intro {
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.intro {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
}

.box {
    padding: 0 20px;
}

.post-author-avatar,
.post-comment-avatar,
.post-new-comment-avatar {
    @include round-avatar ($color-3);
}

.post-author {
    display: flex;
    flex-direction: row;
    background-color: $color-2;
    color: $color-4;
    padding: 15px 0;
    padding-left: 90px;
    position: relative;
    justify-content: space-between;
    padding-right: 25px;

    &-edit {
        background-color: saturate($color-2, 20%) !important;
    }

    &-avatar {
        width: 50px;
        height: 50px;
        position: absolute;
        margin: 10px;
        left: 10px;
        top: 5px;
    }

    &-name {
        font-size: 1em;
        font-weight: 500;
        margin-left: 0;

        @include mobile-only {
            font-size: 0.8em;
        }
    }

    &-date {
        font-size: 0.8em;
        font-weight: normal;
    }

    &-pictos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-picto {

        &-edit,
        &-delete {
            @include picto(20px, $color-4, 0.9);
            padding: 0 5px;

            &:hover {
                @include picto-hover(0.7);
            }
        }
    }
}

.post-cache {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;

    &-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0.8;
    }

    &-deleteMsg {
        max-width: 450px;
        text-align: center;
        z-index: 6;
        margin: auto;
        background-color: white;
        opacity: 1;
        padding: 25px;
        border-radius: 15px;
        font-weight: bold;

        border: 3px solid $color-2;

        & .buttons {
            margin-top: 20px;

            & .bt:focus {
                border: none !important;
            }
        }
    }
}


.post-publication {
    display: flex;
    flex-direction: column;
    // align-items: center;

    &-title {
        font-size: 1.4em;
        line-height: auto;
        font-weight: bold;
        margin: 15px 0 15px 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet-only {
            font-size: 1.2em;
        }

        @include mobile-only {
            font-size: 1em;
            margin-left: 0;
            justify-content: center;
        }
    }

    &-image {
        max-width: 100%;
        display: flex;
        justify-content: center;

        & img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            object-fit: contain;
        }
    }

    .upload-button {
        @include button(darken($color-3, 20%));
        width: 50%;
        outline: none;
        margin: auto;
        margin-bottom: 15px;

        &:before {
            font-family: serif;
        }
    }

    .bt {
        align-self: center;
    }
}


.post-interactions {
    border-top: 1px solid (lighten($color-3, 10%));
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;

    align-items: center;


    &-comments {
        display: flex;
        align-items: center;

        &-picto {
            font-size: 20px;
            margin-right: 10px;
        }

        &-number {
            font-weight: normal;
            font-size: 12px;
        }

        & .fa-caret-square-down,
        .fa-caret-square-up,
        .fa-sync {
            margin-left: 5px;
            padding: 8px;
            font-size: 1.2em;

            &:hover {
                cursor: pointer;
                color: $color-2;
            }

            &:active {
                color: darken($color-2, 20%);
            }
        }
        .fa-sync {
            font-size: 15px;
        }
    }
}

// pour le champ d'ajout de commentaire
.warning-field {
    border: 3px solid $color-2!important;
}

.post-comments {
    & .post-comment {
        padding-bottom: 15px;

        &-bloc1 {
            display: flex;
            align-items: center;

            & .post-comment-avatar {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            & .post-comment-name {
                font-size: 1em;
                font-weight: 600;
                line-height: 1.5em;

                @include mobile-only {
                    font-size: 0.9em;
                }
            }

            & .post-comment-date {
                font-size: 0.75em;

                @include mobile-only {
                    font-size: 0.7em;
                }
            }

            & .post-comment-pictos {
                min-width: 40px;
                display: flex;
                justify-content: space-between;
                margin: 0 15px;
            }

            & .post-comment-picto {

                &-edit,
                &-delete {
                    @include picto(17px, $color-1, 0.2);

                    &:hover {
                        @include picto-hover(0.7);
                    }
                }

            }

            & .isEditMode {
                color: $color-5;
                font-size: 1.4em;
                line-height: 0em;

                &:hover {
                    cursor: pointer;
                    color: darken($color-5, 15%)
                }
            }

            &-b {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
            }
        }

        &-bloc2 {
            textarea.post-comment-message {
                font-size: 16px;
                margin-left: 50px;
                font-weight: 400;
                line-height: 1.4em;
            }
        }

        &-publication {
            margin-top: 15px;

            @include mobile-only {
                font-size: 0.9em;
                ;
            }
        }
    }

    // séparateur
    & .post-comment:before {
        content: "";
        width: calc(100% + 40px);
        height: 1px;
        margin-left: -20px;
        background-color: lighten($color-3, 10%);
        display: block;
        margin-bottom: 10px;
    }
}

.post-new-comment {
    border-top: 1px solid (lighten($color-3, 10%));
    display: flex;
    align-items: center;
    position: relative;

    &-avatar {
        width: 40px;
        height: 40px;
        min-width: 35px;
        min-height: 35px;
        margin: 15px 0;
    }

    &-message {
        font-size: 0.875em;
        font-weight: 300;
        background-color: lighten($color-3, 15%);
        border-radius: 30px;
        flex-grow: 1;
        margin-left: 20px;
        border: none;
        outline: none;
        padding: 10px 15px;

        overflow: hidden;
        text-overflow: ellipsis;

        &::placeholder {
            color: darken($color-3, 10%);
        }
    }
}

.post-new-comment-send {
    position: absolute;
    // right: 35px;
    right: 25px;
    color: darken($color-3, 10%);
    font-size: 0.7em;
    font-weight: 300;

    & .fa-arrow-circle-right {
        font-size: 2.5em;

        &:hover {
            color: darken($color-3, 35%);
            cursor: pointer;
        }

        &:active {
            color: $color-2;
        }
    }

    @include tablet-only {
        // display: none;
        // FIXME: ajouter un MQ spécifique pour que texte reste dans le champs jusqu'a >461px puis >460px idem mobile-only
    }

    @include mobile-only {
        font-size: 0.55em;
    }
}

.bt-new-publication {
    position: fixed;
    background-color: $color-2;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    z-index: 11;

    @include mobile-only {
        bottom: 10px;
        right: 10px;
    }

    // ombre du bouton
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 300ms,
    box-shadow 300ms;

    // ombre du bouton
    &:after {
        content: "";
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
        transform: scale(1.1);

        &:after {
            opacity: 1;
        }
    }

    & i {
        position: relative;
        margin: 15px;
        left: 5%;
        top: -2px;
        font-size: 38px;
        color: $color-4;

        @include tablet-only {
            font-size: 30px;
            margin: 12px;
            left: 5%;
            top: -2px;
        }
    }
}