@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

$font-1: 'Montserrat',
sans-serif;

$color-1: #0B1F42;
$color-2: #CF525D;
$color-3: #C4C4C4;
$color-4: #FFFFFF;
$color-5: #6FCF97;

//  NOTE:  Breakpoints Media queries 
$breakpoint-mobile: 375px;
$breakpoint-tablet: 750px;
$breakpoint-desktop: 751px;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: auto;
  font-family: $font-1;
  color: $color-4;
  background-color: $color-1;
}

a {
  text-decoration: none;
  color: $color-2;
  cursor: pointer;
}

.main {
  margin: 30px;
  margin-bottom: 100px;

  @include tablet-only {
    margin: 25px;
    margin-bottom: 100px;
  }

  @include mobile-only {
    margin: 20px;
    margin-bottom: 60px;
  }
}

*>img {
  width: 100%;
  height: 100%;
}

// bouton de validation
.bt {
  @include button($color-2);
}

.bt-cancel {
  @include button($color-3);
}

.invisible {
  display: none !important;
}

.error-msg {
  color: $color-2;
  height: 20px;
  font-size: 0.9em;
  font-weight: normal;
  align-self: flex-start;
}

.onFocus {
  color: $color-1;
  outline: 1px solid lighten($color-1, 50%);
  border-radius: 5px;
}

.textareaAutosize {
  // Titre : largeur - décalage marge de gauche - marge
  width: calc(100% - 50px - 25px);
  border: none;
  background-color: $color-4;
  color: $color-1;
  font-family: $font-1;
  resize: none;
}

.highlights {
  background-color: #FFEAC2;
}