.page404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 200px;
    max-width: 600px;
}

.page404 h1 {
    font-size: 80px;
}

.message404 {
    font-size: 50px;
    margin-bottom: 35px;
}