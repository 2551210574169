.new-post-container {
    max-width: 600px !important;

    & .bt-close {
        @include bt-icon($color-4, 1.2em);
        padding: 0 5px;

        &:hover {
            color: darken($color-2, 20%);
        }
    }
}

.post-new-publication {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;

    & input[type="text"] {
        @include field;
        width: 100%;
        background-color: lighten($color-3, 15%);
        color: #000;
        font-family: $font-1;
        margin-bottom: 10px;

        &::placeholder {
            color: darken($color-3, 15%);
        }
    }

    & label {
        margin-top: 10px;
    }

    & .upload-button {
        @include button(darken($color-3, 20%));
        margin: 15px 0 30px 0;
        outline: none;

        &:before {
            font-family: serif;
        }
    }

    .error-msg {
        align-self: center;
    }
}

.bt:focus {
    border: 2px solid $color-1;
}