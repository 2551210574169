@mixin round-avatar($colour) {
    border: 2px solid $colour;
    border-radius: 50%;
    overflow: hidden;
    background-color: lighten($color-3, 12%);
}

@mixin field {
    padding: 10px;
    border-radius: 4px;
    font-size: 1em;
    border: 0;
    outline: none;
}

@mixin button($colour) {
    background-color: $colour;
    color: $color-4;
    padding: 10px 50px;
    margin: 10px;
    border-radius: 8px;
    font-size: 1em;
    font-weight: 400;
    border: none;
    outline: none;

    &:hover {
        cursor: pointer;
        background-color: darken($colour, 15%);
    }

    &:focus {
        border: 1px solid white!important;
    }

    &:active {
        background-color: darken($colour, 30%);
    }//  NOTE:  Breakpoints Media queries 
$breakpoint-mobile: 375px;
$breakpoint-tablet: 750px;
$breakpoint-desktop: 751px;
}

@mixin bt-icon($colour, $font-size) {
    padding: 10px 15px;
    border-radius: 5px;
    color: $colour;
    font-size: 1.2em;
}

@mixin bt-icon-hover {
    background-color: lighten($color-3, 15%);
    cursor: pointer;
}

@mixin picto($font-size, $color, $opacity) {
    color: $color;
    opacity: $opacity;
    font-size: $font-size;
    transition: opacity 250ms;
}

@mixin picto-hover ($opacity) {
    opacity: $opacity;
    cursor: pointer;
}

@mixin pictoSurrounded($font-size) {
    color: $color-5 !important;
    opacity: 1 !important;
    font-size: $font-size !important;
}

@mixin picto-hoverSurrounded {
    cursor: pointer;
    color: darken($color-5, 20%) !important;
}

@mixin infobulle {
    position: absolute;
    max-width: 260px;
    background-color: lighten($color-3, 10%);
    color: $color-1;
    font-weight: 500 !important;
    line-height: 1.5em;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
    hyphens: auto;
    font-family: $font-1 !important;
    z-index: 7;
    box-shadow: 0 0 15px 0 #00000088;
    transform: translateX(140px) translateY(calc(-50% - 14px));
}

@mixin infobulle-before {
    position: absolute;
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    border-color: #C4C4C400;
    border-right-color: lighten($color-3, 10%);
    border-width: 10px;
    margin-top: -10px;
}


// NOTE:  Medias Queries
@mixin mobile-only {
    @media screen and (max-width: $breakpoint-mobile) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin desktop-only {
    @media screen and (min-width: $breakpoint-desktop) {
        @content;
    }
}