.login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	& .welcome {
		font-weight: 500;
		font-size: 2.75em;
		line-height: 1.2em;
		margin: 40px 0 18px 0;

		@include mobile-only {
			font-size: 2em;
		}
	}

	& .filet {
		height: 2px;
		width: 200px;
		background-color: $color-2;

		@include mobile-only {
			width: 150px;
		}
	}

	& .connect {
		font-weight: 500;
		font-size: 1.5em;
		line-height: 1em;
		margin: 22px 0 25px 0;

		@include mobile-only {
			font-size: 1.2em;
			line-height: 0.5em;
		}
	}

	& label {
		font-size: 0.9em;
		align-self: flex-start;
	}

	& input[type] {
		@include field;
		width: 100%;
	}

	& .field-bloc {
		width: 100%;
		margin-bottom: 5px;
		position: relative;
	}

	& .required-field {
		font-size: 0.7em;
	}

	& .red {
		color: $color-2;
	}
}


.infobulle {
	@include infobulle;
	opacity: 0;
	transition: opacity 300ms;
}

.infobulle:before {
	@include infobulle-before;
}


.fa-info-circle:hover + .infobulle {
	opacity: 1;
	cursor: pointer;
}

.login-form,
.signup-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 400px;
	margin-top: 20px;
	line-height: 1.6em;
	font-weight: 300;
	padding: 0 15px;
}

// ----  Login only ----
.signup-link {
	font-size: 0.9em;
	text-align: center;
	align-self: center;
	margin-bottom: 30px;
}

// ----  SignUp only ----

.signup-avatar {
	position: relative;
	background-color: $color-3;
	width: 100px;
	height: 100px;
	margin: 25px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	overflow: hidden;

	& img {
		transition: opacity 300ms;
	}

	&:hover img {
		opacity: 0.2;
	}

	&-text-hover {
		opacity: 0;
		position: absolute;
		color: $color-1;
		text-align: center;
		font-weight: 500 !important;
		width: 100%;
		font-size: 13px;
		transition: opacity 300ms;
	}

	&:hover {
		cursor: pointer;
	}

	&:hover .signup-avatar-text-hover {
		opacity: 1;
	}

	& input[type="file"] {
		display: none;
	}
}

.adminConnexion {
	width: 100%;
	display: flex;
	margin-bottom: 15px;

	& label {
		font-size: 0.8em;
	}

	& .checkbox {
		width: min-content !important;
		align-self: center;
		margin-right: 10px;
	}

	& .adminPassword {
		border: 1px solid red;
		height: 25px;
		max-width: 100px;
		margin-left: 20px;
		font-size: 14px !important;

		&::placeholder {
			color: rgb(151, 151, 151) !important;
		}
	}
}
